import {Moment} from "moment";

export function formatNumber(value: number, {digits}: {digits?: number}): string {
  let opts: Intl.NumberFormatOptions = {};
  if (digits !== undefined) {
    opts.minimumFractionDigits = digits;
    opts.maximumFractionDigits = digits;
  }
  return new Intl.NumberFormat("cs-CZ", opts).format(Number(value));
}

export function getPrevBusinessDay(date: Moment): Moment {
  let isBusinessDay = false;
  while (!isBusinessDay) {
    date = date.subtract(1, "day");
    isBusinessDay = date.isoWeekday() < 6;
  }
  return date;
}

export function getTextDecoder() {
  // jest tests run on node.js and there is no TextDecoder there
  if ((window as any).TextDecoder) {
    return (window as any).TextDecoder;
  } else {
    let {TextDecoder} = require("util");
    return TextDecoder;
  }
}

export function removeExt(filename: string): string {
  return filename.replace(/\.[^.]+$/, "");
}

export function dataUrl(data: string) {
  return "data:text/plain;charset=utf-8," + encodeURIComponent(data);
}
