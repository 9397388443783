import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import CodPreview from "./cod/CodPreview";
import Home from "./Home";
import "./layout.css";
import {AppStoreContext} from "./AppStoreContext";
import AppStore from "./AppStore";
import CardPreview from "./card/CardPreview";

const App = observer(() => {
  const appStore = useContext(AppStoreContext);

  return (
    <div className="App-body">
      {renderScreen(appStore)}
    </div>
  );
});

function renderScreen(appStore: AppStore) {
  switch (appStore.screen) {
    case "home":
      return <Home />
    case "cod":
      return <CodPreview />
    case "card":
      return <CardPreview />
    default:
      throw new Error(`Unknown screen ${appStore.screen}`);
  }
}

export default App;
