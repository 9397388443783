export async function readFile(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onabort = () => {
      reject(new Error(`File read aborted for file ${file.name}`));
    };
    reader.onerror = () => {
      reject(new Error(`File read has failed for file ${file.name}`));
    };
    reader.onload = () => {
      if (reader.result === null) {
        return reject(new Error(`Result is null for file ${file.name}.`));
      }
      if (!isArrayBuffer(reader.result)) {
        return reject(new Error(`Unexpected result type: ${typeof reader.result}`));
      }
      resolve(reader.result);
    };

    reader.readAsArrayBuffer(file);
  });
}

function isArrayBuffer(value: any): value is ArrayBuffer {
  return value instanceof ArrayBuffer;
}

