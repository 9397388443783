import {ColumnMapping} from "./cod/CodStore";

class AppConfig {

  codAccounts: BankAccount[] = [
    {number: "11401010-00005433-51001001", currency: "PLN"},
    {number: "0000002284119001", currency: "CZK"},
    {number: "00000-3091530659", currency: "EUR"},
    {number: "12010501-01378069-00100005", currency: "HUF"},
    {number: "RO60RZBR0000060015381767", currency: "RON"}
  ];

  cardAccounts: BankAccount[] = [
    {number: "266320570", currency: "CZK"},
    {number: "287681922", currency: "HUF"},
    {number: "292835922", currency: "EUR"},
    {number: "292836028", currency: "RON"},
    {number: "287682175", currency: "PLN"}
  ];

  presets: Preset[] = [
    {
      name: "Česká Pošta",
      accountNumber: "0000002284119001",
      columnMappings: [
        {index: 1, type: "name"},
        {index: 4, type: "amount"},
        {index: 5, type: "vs"}
      ],
      headerRows: 1,
      footerRows: 1,
      downloadType: "gpc"
    },
    {
      name: "DHL (CZK)",
      accountNumber: "0000002284119001",
      columnMappings: [
        {index: 1, type: "name"},
        {index: 3, type: "amount"},
        {index: 4, type: "vs"}
      ],
      headerRows: 1,
      downloadType: "gpc"
    },
    {
      name: "GLS",
      accountNumber: "11401010-00005433-51001001",
      columnMappings: [
        {index: 3, type: "amount"},
        {index: 5, type: "vs"},
        {index: 7, type: "name"},
      ],
      headerRows: 2,
      downloadType: "cdf"
    },
    {
      name: "Multimex (EUR)",
      accountNumber: "00000-3091530659",
      columnMappings: [
        {index: 2, type: "vs"},
        {index: 3, type: "amount"},
        {index: 7, type: "name"}
      ],
      headerRows: 1,
      downloadType: "cdf"
    },
    {
      name: "Multimex (PLN)",
      accountNumber: "11401010-00005433-51001001",
      columnMappings: [
        {index: 0, type: "name"},
        {index: 3, type: "amount"},
        {index: 4, type: "currency"},
        {index: 6, type: "vs"}
      ],
      headerRows: 1,
      downloadType: "cdf"
    },
    {
      name: "PPL (CZK)",
      accountNumber: "0000002284119001",
      columnMappings: [
        {index: 0, type: "name"},
        {index: 1, type: "amount"},
        {index: 2, type: "currency"},
        {index: 3, type: "vs"}
      ],
      headerRows: 1,
      downloadType: "gpc"
    },
    {
      name: "PPL (HUF)",
      accountNumber: "12010501-01378069-00100005",
      columnMappings: [
        {index: 0, type: "name"},
        {index: 1, type: "amount"},
        {index: 2, type: "currency"},
        {index: 3, type: "vs"}
      ],
      headerRows: 1,
      downloadType: "cdf"
    },
    {
      name: "PPL (EUR)",
      accountNumber: "00000-3091530659",
      columnMappings: [
        {index: 0, type: "name"},
        {index: 1, type: "amount"},
        {index: 2, type: "currency"},
        {index: 3, type: "vs"}
      ],
      headerRows: 1,
      downloadType: "cdf"
    },
    {
      name: "PPL (PLN)",
      accountNumber: "11401010-00005433-51001001",
      columnMappings: [
        {index: 0, type: "name"},
        {index: 1, type: "amount"},
        {index: 2, type: "currency"},
        {index: 3, type: "vs"}
      ],
      headerRows: 1,
      downloadType: "cdf"
    },
    {
      name: "PPL (RON)",
      accountNumber: "RO60RZBR0000060015381767",
      columnMappings: [
        {index: 0, type: "name"},
        {index: 1, type: "amount"},
        {index: 2, type: "currency"},
        {index: 3, type: "vs"}
      ],
      headerRows: 1,
      downloadType: "cdf"
    },
    {
      name: "TOF (RON)",
      accountNumber: "RO60RZBR0000060015381767",
      columnMappings: [
        {index: 3, type: "vs"},
        {index: 6, type: "name"},
        {index: 8, type: "amount"},
        {index: 9, type: "currency"}
      ],
      headerRows: 1,
      footerRows: 1,
      downloadType: "cdf"
    },
    {
      name: "TOF (HUF)",
      accountNumber: "12010501-01378069-00100005",
      columnMappings: [
        {index: 1, type: "vs"},
        {index: 2, type: "name"},
        {index: 3, type: "amount"}
      ],
      headerRows: 1,
      downloadType: "cdf"
    }
  ]

}

export const appConfig = new AppConfig();

export interface BankAccount {
  number: string;
  currency: string;
}

export interface Preset {
  name: string;
  accountNumber: string;
  columnMappings: ColumnMapping[];
  downloadType: DownloadType;
  headerRows?: number;
  footerRows?: number;
}

export type DownloadType = "cdf" | "gpc"
