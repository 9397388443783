import React from "react";
import "./shared/Upload.scss";
import "./layout.css";
import {AppBar, Grid, Toolbar, Typography} from "@material-ui/core";
import { CodUpload } from "./cod/CodUpload";
import {CardUpload} from "./card/CardUpload";

function Home() {

  return (
    <div className="App-container">
      <div className="App-header">
        <AppBar position="relative" color="default">
          <Toolbar>
            <Typography variant="h6" color="inherit">
              Rozúčtování hromadných plateb
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
      <div className="App-body">
        <Grid container spacing={0} direction="row" alignItems="center" justify="center" style={{height: "100%"}}>
          <Grid item xs={3}>
            <h2>Dobírky</h2>
            <CodUpload />
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={3}>
            <h2>Platby kartou</h2>
            <CardUpload />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}


export default Home;
