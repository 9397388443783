import React, {useContext} from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Select,
  MenuItem,
  TextField,
  Grid,
  InputLabel,
  FormControl,
  Typography, AppBar, Toolbar, Button
} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import {ColumnMapping} from "./CodStore";
import moment from "moment";
import {dataUrl, formatNumber} from "../shared/utils";
import {AppStoreContext} from "../AppStoreContext";
import {DownloadType} from "../AppConfig";

function CodPreview() {
  const appStore = useContext(AppStoreContext);
  const {codStore} = appStore;
  let selectedPreset = codStore.selectedPreset ? codStore.selectedPreset.name : "";

  let downloadFilename = codStore.filename + (codStore.selectedDownloadType === "cdf" ? ".cdf" : ".gpc");

  return (
    <div className="App-container">
      <div className="App-header">
        <AppBar position="relative" color="default">
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="h6" color="inherit" style={{marginRight: "12px"}}>
                  {codStore.filename}
                </Typography>
              </Grid>
              <Grid item>
                <FormControl>
                  <InputLabel htmlFor="preset">Preset</InputLabel>
                  <Select value={selectedPreset} onChange={e => codStore.selectPreset(e.target.value)} style={{width: "170px", color: "inherit"}} inputProps={{
                    name: 'preset',
                    id: 'preset',
                  }}>
                    <MenuItem value="" />
                    { codStore.presets.map(preset => (
                      <MenuItem key={preset.name} value={preset.name}><Typography variant="inherit" color={codStore.matchingPresets.includes(preset.name) ? "textPrimary" : "textSecondary"}>{preset.name}</Typography></MenuItem>
                    )) }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <TextField
                    id="date"
                    type="date"
                    label="Datum"
                    defaultValue={codStore.date.format("YYYY-MM-DD")}
                    onChange={(e) => codStore.date = moment(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <InputLabel htmlFor="account">Bankovní účet</InputLabel>
                  <Select value={codStore.selectedAccount.number} onChange={ (e) => codStore.selectAccount(e.target.value) } inputProps={{
                    name: 'account',
                    id: 'account',
                  }}>
                    { codStore.accounts.map(account => (
                      <MenuItem key={account.number} value={account.number}>{account.number} ({account.currency})</MenuItem>
                    )) }
                  </Select>
                </FormControl>
              </Grid>
              { codStore.totalAmount > 0 &&
                <Grid item style={{marginLeft: "90px"}}>
                  Celková částka: <b>{formatNumber(codStore.totalAmount, {digits: 2})}</b>
                </Grid>
              }
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
      <div className="App-body">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  { codStore.columnMappings.map(columnMapping => (
                    <TableCell key={columnMapping.index}>
                      <Select value={columnMapping.type} onChange={e => codStore.changeColumnMapping(columnMapping.index, e.target.value)}>
                        <MenuItem value="">(Nevybráno)</MenuItem>
                        { ColumnMapping.COLUMN_TYPES.map(type => (
                          <MenuItem key={type.id} value={type.id}>{type.label}</MenuItem>
                        )) }
                      </Select>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                { codStore.rows.map(row => (
                  <TableRow key={row.index}>
                    <TableCell>
                      <Checkbox color="default" checked={row.include} onChange={(e, checked) => row.include = checked} />
                    </TableCell>
                    { row.cells.map((cell) => (
                      <TableCell key={cell.index} style={{color: cell.color}}>
                          { cell.formattedValue }
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </div>
      <div className="App-footer">
        <AppBar position="relative" color="default">
          <Toolbar>
            <Grid container spacing={2} justify="space-between" alignItems="center">
              <Grid item>
                { codStore.hasAllRequiredMappings &&
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <FormControl>
                        <InputLabel htmlFor="downloadType">Výstupní formát</InputLabel>
                        <Select value={codStore.selectedDownloadType} onChange={e => codStore.selectedDownloadType = e.target.value as DownloadType} style={{width: "170px"}} inputProps={{
                          name: 'downloadType',
                          id: 'downloadType',
                        }}>
                          <MenuItem value="cdf">CDF</MenuItem>
                          <MenuItem value="gpc">GPC</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        href={dataUrl(codStore.selectedDownloadType === "cdf" ? codStore.cdfData : codStore.gpcData)}
                        download={downloadFilename}
                      >
                        Stáhnout {downloadFilename}
                      </Button>
                    </Grid>
                  </Grid>
                }
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={() => appStore.reset()}>
                  Načíst další
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
}


export default observer(CodPreview);
