import React, {useContext} from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  AppBar, Toolbar, Typography, Grid, Button, FormControl, TextField
} from "@material-ui/core";
import {AppStoreContext} from "../AppStoreContext";
import {makeStyles} from "@material-ui/core/styles";
import {dataUrl, formatNumber} from "../shared/utils";
import moment from "moment";
import {observer} from "mobx-react-lite";

const useStyles = makeStyles({
  body: {
    padding: "20px"
  },
  container: {
    // maxHeight: "calc(80vh)",
    minWidth: "500px"
  },
  tableFooter: {
    fontWeight: 500
  },
  group: {
    backgroundColor: "#f5f5f5",
    padding: "20px"
  },
  groupHeader: {
  },
  groupSubheader: {
    color: "grey"
  },
  groupFooter: {
    marginTop: "20px"
  }
});

export function CardPreview() {
  const appStore = useContext(AppStoreContext);
  const {cardStore} = appStore;

  const classes = useStyles();

  return (
    <div className="App-container">
      <div className="App-header">
        <AppBar position="relative" color="default">
          <Toolbar>
            <Typography variant="h6" color="inherit">
              Rozúčtování hromadných plateb
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
      <div className={`App-body ${classes.body}`}>
        <Grid container spacing={6} direction="column" alignItems="center" justify="center">
          {cardStore.batchGroups.map((batchGroup) => (
            <Grid item key={batchGroup.key}>
              <Paper className={classes.group} elevation={3}>

                <h1 className={classes.groupHeader}>
                  <Grid container direction="row" alignItems="baseline" justify="space-between">
                    <span>{`${batchGroup.account.number} (${batchGroup.account.currency})`}</span>
                    <small className={classes.groupSubheader}>{batchGroup.date.format("L")}</small>
                  </Grid>
                </h1>

                {batchGroup.batches.map((batch) => (
                  <div key={batch.key}>
                    <h4>Dávka {batch.key + 1}</h4>
                    <TableContainer className={classes.container}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">Částka (včetně poplatku)</TableCell>
                            <TableCell align="right">Poplatek</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {batch.rows.map((row) => (
                            <TableRow key={row.key}>
                              <TableCell></TableCell>
                              <TableCell align="right">{formatNumber(row.grossAmount, {digits: 2})}</TableCell>
                              <TableCell align="right">{formatNumber(row.fee, {digits: 2})}</TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell align="right" className={classes.tableFooter}>Celkem:</TableCell>
                            <TableCell align="right" className={classes.tableFooter}>{formatNumber(batch.grossAmountTotal, {digits: 2})}</TableCell>
                            <TableCell align="right" className={classes.tableFooter}>{formatNumber(batch.feeTotal, {digits: 2})}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ))}


                <Grid container spacing={2} justify="space-between" alignItems="center" className={classes.groupFooter}>
                  <Grid item>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <FormControl>
                          <TextField
                            id="date"
                            type="date"
                            label="Datum"
                            defaultValue={batchGroup.date.format("YYYY-MM-DD")}
                            onChange={(e) => {
                              batchGroup.date = moment(e.target.value)
                            }}
                          />
                        </FormControl>
                        <FormControl>
                          <TextField label="Účet" value={`${batchGroup.account.number} (${batchGroup.account.currency})`} InputProps={{
                            readOnly: true,
                          }} />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      href={dataUrl(batchGroup.gpcData)}
                      download={batchGroup.downloadFilename}
                    >
                      Stáhnout {batchGroup.downloadFilename}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="App-footer">
        <AppBar position="relative" color="default">
          <Toolbar>
            <Grid container justify="flex-end">
              <Grid item>
                <Button variant="contained" color="secondary" onClick={() => appStore.reset()}>
                  Načíst další
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  )
};

export default observer(CardPreview);
