import {Moment} from "moment";

export class Gpc {

  private _value = "";
  private _accountNumber: string;
  private _date: Moment;

  header(accountNumber: string, date: Moment) {
    this._accountNumber = accountNumber.padStart(16, "0");
    this._date = date;
    let date_ = this._date.format("DDMMYY");

    this._value += `074${this._accountNumber}unitechnic.cz s.r.o.08031300000000000000+00000000000000+000000000000000000000000000000000${date_}              \r\n`;
    return this;
  }

  transaction(name: string, amount: number, vs: string) {
    let name_ = name.padEnd(20);
    let vs_ = vs.padStart(10, "0");

    let date_ = this._date.format("DDMMYY");
    let cisloDokladu = this._date.format("MMDD").padEnd(13, "0");

    let amount_ = (Math.abs(amount) * 100).toFixed(0).padStart(12, "0");
    let sign = amount >= 0 ? "2" : "1";

    // TODO: co kdyz name nebo vs pretece?
    // TODO: musi cislo uctu obsahovat 0300?
    this._value += `075${this._accountNumber}0000000000000000${cisloDokladu}${amount_}${sign}${vs_}00000003080000000000${date_}${name_}01081${date_}\r\n`;
    return this;
  }

  toString() {
    return this._value;
  }

}
