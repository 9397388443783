import {Button, Grid, Icon, Typography} from "@material-ui/core";
import React, {useCallback, useContext} from "react";
import {useDropzone} from "react-dropzone";
import {readFile} from "../shared/read-file";
import {AppStoreContext} from "../AppStoreContext";

export function CardUpload() {
  const appStore = useContext(AppStoreContext);
  const {cardStore} = appStore;

  // TODO: error handling
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 1) {
      throw new Error(`Can process only one file at a time`);
    }
    let file = acceptedFiles[0];
    let data = await readFile(file);
    cardStore.loadFile(file.name, data);
    appStore.screen = "card";
  }, [cardStore, appStore.screen]);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return (
    <div className={`Upload-box ${isDragActive && "Upload-dragging"}`} {...getRootProps()}>
      <Grid container direction="column" spacing={3} alignItems="center">
        <input {...getInputProps()} />
        <Grid item xs={12}>
          <Icon className="Upload-icon">cloud_upload</Icon>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Přetáhněte soubor sem</Typography>
        </Grid>
        <Grid item xs={12}>
          nebo
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary">Vyberte soubor</Button>
        </Grid>
      </Grid>
    </div>
  );
}
