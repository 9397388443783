import {computed, observable, transaction} from "mobx";
import moment, {Moment} from "moment";
import _ from "lodash";
import {getTextDecoder} from "../shared/utils";
import {appConfig} from "../AppConfig";
import {Gpc} from "../shared/gpc";
const TextDecoder = getTextDecoder();

export default class CardStore {

  @observable filename: string = "";

  @observable batchGroups: CardBatchGroup[] = [];

  loadFile(filename: string, data: ArrayBuffer) {
    transaction(() => {
      console.log(`Going to load ${filename}`);
      this.filename = filename;

      let enc = new TextDecoder("utf-8");
      let dataString = enc.decode(data);
      let lines = dataString.split(`\r\n`);

      let batches: CardBatch[] = [];
      let currentBatch = new CardBatch();
      let rowKey = 0;
      let accountNr = "";
      let date = "";

      for (let line of lines) {
        if (line.startsWith("0127425134")) {
          date = line.substring(48, 58).trim();
        } else if (line.startsWith("0253627195")) {
          accountNr = line.substring(56, 73).trim();
        } else if (line.startsWith("03MIPS5752")) {
          let row = {
            key: rowKey++,
            grossAmount: this._parseAmount(line.substring(57, 83)),
            fee: parseFloat(line.substring(83, 99)),
            vs: line.substring(32, 41)
          }
          // Fee does not have sign in the input file. If grossAmount is negative, fee is also negative (AFAIK this happens when
          // customer cancels payment transaction and is refunded).
          if (row.grossAmount < 0) {
            row.fee = row.fee * -1;
          }
          currentBatch.rows.push(row);
        } else if (line.startsWith("040002")) {
          currentBatch.accountNr = accountNr;
          currentBatch.date = moment(date, "YYYY-MM-DD", true);
          if (!currentBatch.date.isValid()) {
            throw new Error(`Invalid date: ${date}`);
          }
          currentBatch.grossAmountTotal = this._parseAmount(line.substring(16, 34));
          currentBatch.feeTotal = parseFloat(line.substring(34, 50));
          // Fee does not have sign in the input file. If grossAmount is negative, fee is also negative (AFAIK this happens when
          // customer cancels payment transaction and is refunded).
          if (currentBatch.grossAmountTotal < 0) {
            currentBatch.feeTotal = currentBatch.feeTotal * -1;
          }
          batches.push(currentBatch);
          currentBatch = new CardBatch();
          rowKey = 0;
        }
      }

      // we get multiple batches per account in single input file, group into as few batches as possible
      let groups = _.groupBy(batches, batch => `${batch.account.number}/${batch.date}`);
      let groupKey = 0;
      let batchKey = 0;
      for (let groupBatches of Object.values(groups)) {
        let group = new CardBatchGroup(groupBatches);
        group.date = groupBatches[0].date;
        group.key = groupKey++;

        for (let batch of groupBatches) {
          batch.key = batchKey++;
        }

        this.batchGroups.push(group);
      }
    });
  }

  _parseAmount(s: string): number {
    let amount = s.substring(0, s.length - 2);
    let suffix = s.substring(s.length - 2);
    let sign = 0;
    switch (suffix) {
      case "CR":
        sign = 1;
        break;
      case "DB":
        sign = -1;
        break;
      default:
        throw new Error(`Unexpected sign suffix: ${suffix}`)
    }

    return parseFloat(amount) * sign;
  }

}

/**
 * Batch of card payments.
 */
class CardBatch {
  key = 0;

  rows: CardRow[] = []

  date: Moment;
  accountNr = "";
  feeTotal = 0;
  grossAmountTotal = 0;

  @computed get account() {
    let account = appConfig.cardAccounts.find(account => account.number === this.accountNr);
    if (!account) {
      throw new Error(`Account not found: ${this.accountNr}`);
    }
    return account;
  }

  writeGpc(gpc: Gpc) {
    for (let row of this.rows) {
      // TODO: name
      let name = "";
      gpc.transaction(name, row.grossAmount, row.vs);
    }
    // add one transaction for the sum of fees
    // TODO: name
    gpc.transaction("", -this.feeTotal, "");
  }
}

/**
 * We typically get multiple batches for one account within single input file. We group these batches into single
 * group, so we can then generate just one GPC file.
 */
class CardBatchGroup {
  key = 0;

  @observable date: Moment;
  @observable batches: CardBatch[];

  constructor(
    batches: CardBatch[]
  ) {
    if (batches.length === 0) {
      throw new Error("Something is wrong, batch length is 0")
    }

    this.batches = batches;
  }

  @computed get downloadFilename() {
    return `${this.date.format("YYYY-MM-DD")}-${this.account.currency}-${this.account.number}.gpc`;
  }

  @computed get account() {
    return this.batches[0].account;
  }

  @computed get gpcData() {
    let gpc = new Gpc();
    gpc.header(this.account.number, this.date);

    for (let batch of this.batches) {
      batch.writeGpc(gpc);
    }

    return gpc.toString();
  }

}



interface CardRow {
  key: number;

  /**
   * Credit card payment amount (with fee included)
   */
  grossAmount: number;
  /**
   * Credit card transaction fee.
   */
  fee: number;

  vs: string;
}
