import {computed, observable, transaction} from 'mobx';
import moment, {Moment} from "moment";
import {appConfig, BankAccount, DownloadType, Preset} from "../AppConfig";
import xlsx, {CellObject} from "xlsx";
import {sortBy, sum} from "lodash";
import {formatNumber, getPrevBusinessDay, getTextDecoder, removeExt} from "../shared/utils";
import {Gpc} from "../shared/gpc";
const TextDecoder = getTextDecoder();

export default class CodStore {

  @observable date: Moment = getPrevBusinessDay(moment());

  @observable filename: string = "";

  /**
   * Our bank accounts
   */
  accounts: BankAccount[] = appConfig.codAccounts;

  @observable selectedAccount = this.accounts[0];

  /**
   * Imported data.
   */
  @observable rows: DataTableRow[] = [];

  /**
   * Maps columns from imported data to columns we are interested in (date, name, variable symbol...).
   */
  @observable columnMappings: ColumnMapping[] = [];

  /**
   * Presets with known combinations of column mappings.
   */
  presets: Preset[] = appConfig.presets;

  @observable selectedPreset?: Preset;

  /**
   * Presets that match data.
   */
  @observable matchingPresets: string[] = [];

  loadFile(filename: string, data: ArrayBuffer) {
    transaction(() => {
      console.log(`Going to load ${filename}`);

      this.filename = filename;

      if (removeExt(filename) === "SBDT001078") {
        this._loadCeskaPosta(data);
      } else {
        this._loadExcelOrCsv(data);
      }

      if (!this.rows.length) {
        return;
      }

      // generate empty column mapping for each column in source data
      this.columnMappings = this.rows[0].cells.map((c, index) => new ColumnMapping(index));


      // try to detect correct preset automatically
      let matchingPresets = [];

      for (let preset of appConfig.presets) {
        // up to 2 rows at the beginning can be headers, try to get a third row (if possible)
        let row = this.rows.length > 2 ? this.rows[2] : this.rows[this.rows.length - 1];

        let match = true;
        for (let mapping of preset.columnMappings) {
          let cell = row.cells[mapping.index];
          if (!cell) {
            console.log(`Rejecting preset ${preset.name}, there is not cell at index ${mapping.index} for ${mapping.type}`);
            match = false;
            break;
          }
          let account = appConfig.codAccounts.find(a => a.number === preset.accountNumber);
          if (!account) {
            throw new Error(`Preset ${preset.name}: Account ${preset.accountNumber} not found.`);
          }
          let value = DataTableCell.parse(cell.rawValue, mapping.type);
          if (!DataTableCell.validate(row, value, mapping.type, account)) {
            console.log(`Rejecting preset ${preset.name}, '${cell.value}' is not valid ${mapping.type}`);
            match = false;
            break;
          }
        }

        if (match) {
          matchingPresets.push(preset);
        }
      }

      // select preset that matched most columns
      matchingPresets = sortBy(matchingPresets, p => p.columnMappings.length).reverse();
      let preset = matchingPresets[0];
      if (preset) {
        console.log(`Going to automatically select preset ${preset.name}`);
        this.selectPreset(preset.name);
      }

      this.matchingPresets = matchingPresets.map(p => p.name);
    });
  }

  private _loadExcelOrCsv(data: ArrayBuffer) {
    let workbook = xlsx.read(data, {type: "array"});
    let sheet = workbook.Sheets[workbook.SheetNames[0]];
    if (!sheet["!ref"]) {
      throw new Error("No ref for sheet");
    }
    let range = xlsx.utils.decode_range(sheet["!ref"]);

    for (let rowIndex = range.s.r; rowIndex <= range.e.r; rowIndex++) {
      let row = new DataTableRow(rowIndex);
      for (let colIndex = range.s.c; colIndex < range.e.c; colIndex++) {
        let cell: CellObject = sheet[xlsx.utils.encode_cell({r: rowIndex, c: colIndex})];
        let value = "";
        if (cell) {
          if (cell.w) {
            value = cell.w;
          } else if (cell.v) {
            value = cell.v.toString();
          }
        }
        row.cells.push(new DataTableCell(this, row, colIndex, value, cell))
      }
      this.rows.push(row);
    }
  }

  private _loadCeskaPosta(data: ArrayBuffer) {
    let enc = new TextDecoder("utf-8");
    let dataString = enc.decode(data);
    let lines = dataString.split(`\r\n`);
    for (let rowIndex = 0; rowIndex < lines.length; rowIndex++) {
      let line = lines[rowIndex];
      if (!line) continue;

      let cols = [
        line.substring(0, 1),
        line.substring(1, 14),
        line.substring(14, 20),
        line.substring(20, 30),
        line.substring(30, 41),
        line.substring(41, 51),
        line.substring(51)
      ];

      let row = new DataTableRow(rowIndex);
      for (let colIndex = 0; colIndex < cols.length; colIndex++) {
        row.cells.push(new DataTableCell(this, row, colIndex, cols[colIndex]));
      }
      this.rows.push(row);
    }
  }

  selectPreset(name: unknown) {
    this.clearColumnMappings();

    let preset = this.presets.find(p => p.name === name);

    if (!preset) {
      this.selectedPreset = undefined;
    } else {
      this.selectedPreset = preset;
      this.selectAccount(preset.accountNumber);
      this.selectedDownloadType = preset.downloadType;
      for (let mapping of preset.columnMappings) {
        this.changeColumnMapping(mapping.index, mapping.type);
      }
      if (preset.headerRows) {
        for (let i = 0; i < preset.headerRows; i++) {
          this.rows[i].include = false
        }
      }
      if (preset.footerRows) {
        for (let i = this.rows.length - preset.footerRows; i < this.rows.length; i++) {
          this.rows[i].include = false
        }
      }
    }
  }

  selectAccount(accountNumber: unknown) {
    let account = this.accounts.find(a => a.number === accountNumber);
    if (!account) {
      throw new Error(`Account ${accountNumber} not found.`);
    }
    this.selectedAccount = account;
  }

  clearColumnMappings() {
    for (let mapping of this.columnMappings) {
      mapping.type = "";
    }
  }

  changeColumnMapping(columnIndex: number, columnType: unknown) {
    // unselect type in other columns
    this.columnMappings.filter(m => m.type === columnType).forEach(m => m.type = "");
    this.columnMappings[columnIndex].type = columnType as ColumnTypeId;
  }

  @computed get hasAllRequiredMappings() {
    let types = this.columnMappings.map(m => m.type);
    return types.includes("name") && types.includes("amount") && types.includes("vs");
  }

  @computed get totalAmount() {
    let amountIndex = this.columnMappings.findIndex(c => c.type === "amount");
    if (amountIndex !== -1) {
      return sum(this.rows.filter(row => row.include).map(row => Number(row.cells[amountIndex].value)))
    }
    return 0;
  }

  @observable selectedDownloadType: DownloadType = "cdf";

  @computed get cdfData() {
    let res = "";

    let nameIndex = this.columnMappings.findIndex(c => c.type === "name");
    let amountIndex = this.columnMappings.findIndex(c => c.type === "amount");
    let vsIndex = this.columnMappings.findIndex(c => c.type === "vs");
    // TODO: handle errrors properly
    if (nameIndex === -1 || amountIndex === -1 || vsIndex === -1) {
      throw new Error("Not found")
    }
    let date = this.date.format("DD.MM.YY");
    let account = this.selectedAccount;

    for (let row of this.rows) {
      if (!row.include) continue;

      let name = row.cells[nameIndex].value;
      let amount = Number(row.cells[amountIndex].value).toFixed(2).replace(".", ",");
      let vs = row.cells[vsIndex].value;

      res += `${date},${account.number},"${name}",000000-0000000000,0000,"+${amount}",${account.currency},${vs},308,,,"777"\r\n`
    }
    return res;
  }

  @computed get gpcData() {
    let nameIndex = this.columnMappings.findIndex(c => c.type === "name");
    let amountIndex = this.columnMappings.findIndex(c => c.type === "amount");
    let vsIndex = this.columnMappings.findIndex(c => c.type === "vs");
    // TODO: handle errrors properly
    if (nameIndex === -1 || amountIndex === -1 || vsIndex === -1) {
      throw new Error("Not found")
    }

    let gpc = new Gpc();
    gpc.header(this.selectedAccount.number, this.date);

    for (let row of this.rows) {
      if (!row.include) continue;

      gpc.transaction(row.cells[nameIndex].value, Number(row.cells[amountIndex].value), row.cells[vsIndex].value);
    }

    return gpc.toString();
  }

  reset() {
    transaction(() => {
      this.filename = "";
      this.rows = [];
      this.columnMappings = [];
    })
  }
}

export class DataTableRow {
  index: number;
  cells: DataTableCell[] = [];
  @observable include: boolean = true;

  constructor(key: number) {
    this.index = key;
  }
}

export class DataTableCell {
  index: number;
  rawValue: string;

  private _store: CodStore;
  private _row: DataTableRow;
  private _co?: CellObject; // for debugging purposes

  constructor(store: CodStore, row: DataTableRow, index: number, rawValue: string, co?: CellObject) {
    this._store = store;
    this._row = row;
    this.index = index;
    this.rawValue = rawValue;
    this._co = co;
  }

  static parse(rawValue: string, type: ColumnTypeId): string {
    let value = rawValue.trim();

    switch (type) {
      case "amount":
        // TODO: write tests for this
        if (/^-?\d+([,.]\d+)?$/.test(value)) {
          if (value.startsWith("-")) {
            value = value.substring(1);
          }
          if (value.includes(",")) {
            value = value.replace(",", ".");
          }
        }
        break;
      default:
        // do nothing
    }

    return value;
  }

  static validate(row: DataTableRow, value: string, type: ColumnTypeId, account: BankAccount) {
    if (!row.include) {
      return true;
    }

    switch (type) {
      case "vs":
        // according to GPC variable symbol can have max 10 characters
        return /^\d{1,10}$/.test(value);
      case "amount":
        let isNumber = /^\d+(\.\d+)?$/.test(value);
        return isNumber && Number(value) < 10000000;
      case "currency":
        return value === account.currency;
      case "name":
        // name should not be date
        return !moment(value, DATE_FORMATS, true).isValid();
      default:
        return true;
    }
  }

  @computed get value(): string {
    let mapping = this._store.columnMappings[this.index];
    return DataTableCell.parse(this.rawValue, mapping.type);
  }

  @computed get formattedValue(): string {
    if (!this._row.include || !this.isValid) {
      return this.value;
    }

    let mapping = this._store.columnMappings[this.index];

    switch (mapping.type) {
      case "amount":
        return formatNumber(Number(this.value), {digits: 2});
      default:
        return this.value;
    }
  }

  @computed get isValid(): boolean {
    let mapping = this._store.columnMappings[this.index];
    return DataTableCell.validate(this._row, this.value, mapping.type, this._store.selectedAccount);
  }

  @computed get color(): string  | undefined {
    if (!this.isValid) {
      return "red";
    } else if (this._row.include && this._store.columnMappings[this.index].type !== "") {
      return undefined;
    } else {
      return "#bbb";
    }
  }

  @computed get cellObjectJson(): string {
    return this._co ? JSON.stringify(this._co, null, 2) : "";
  }
}

class ColumnType {
  id: ColumnTypeId;
  label: string;

  constructor(id: ColumnTypeId, label: string) {
    this.id = id;
    this.label = label;
  }
}

export type ColumnTypeId = "" | "name" | "vs" | "amount" | "currency"

export class ColumnMapping {
  static COLUMN_TYPES = [
    new ColumnType("name", "Název"),
    new ColumnType("vs", "Variabilní symbol"),
    new ColumnType("amount", "Částka"),
    new ColumnType("currency", "Měna"),
  ];

  index: number;
  @observable type: ColumnTypeId = "";

  constructor(columnIndex: number) {
    this.index = columnIndex;
  }
}

const DATE_FORMATS = ["YYYY-MM-DD", "D.M.YY", "D.M.YYYY", "M/D/YY", "M/D/YYYY"];
