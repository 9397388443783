import {observable, transaction} from "mobx";
import CodStore from "./cod/CodStore";
import CardStore from "./card/CardStore";

export default class AppStore {

  @observable screen: "home" | "cod" | "card" = "home";

  codStore = new CodStore();
  cardStore = new CardStore();

  reset() {
    transaction(() => {
      this.codStore = new CodStore();
      this.cardStore = new CardStore();
      this.screen = "home";
    })
  };

}
